import styled from '@emotion/styled';

export const FiltersContainer = styled.div`
  ${({ theme: { SPACING, MEDIA_QUERY } }) => `
    padding: ${SPACING.S16} 0;
    ${MEDIA_QUERY.DESKTOP} {
      padding: ${SPACING.S24} 0;
    }
  `}
`;

export const FilterPillsContainer = styled.div`
  margin-top: ${(props) => props.theme.SPACING.S16};
`;
