import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const PriceContainer = styled.div`
  font-size: ${FONT.FONT_SIZE_SMALLER};
  margin-top: ${SPACING.S8};
  display: flex;
  flex-direction: column;
`;

export const RefPriceContainer = styled.div`
  color: ${COLORS3.BLACK_050};
`;

export const Price = styled.div`
  font-weight: ${FONT.FONT_WEIGHT_BOLD};
  margin-bottom: ${SPACING.S16};
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressBar = styled.div<{ percentage?: number }>`
  height: 8px;
  border-radius: 4px;
  background-color: ${COLORS3.BLACK_015};
  flex: 1 1 auto;

  span {
    display: block;
    background-color: ${COLORS3.BLACK_100};
    width: ${(props) => props.percentage || 0}%;
    height: 8px;
    border-radius: 4px;
  }
`;

export const Percent = styled.div`
  width: 48px;
  text-align: center;
  font-size: ${FONT.FONT_SIZE_SMALLER};
  color: ${COLORS3.BLACK_100};
  margin-left: ${SPACING.S8};
`;

export const BottomSection = styled.div`
  margin-top: auto;
  font-size: ${(props) => props.theme.FONTS.FONT_SIZE_SMALLER};
  color: ${(props) => props.theme.COLORS3.BLACK_050};
`;

export const ContributionContainer = styled.div`
  color: ${COLORS3.BLACK_050};
  column-gap: ${SPACING.S16};
  display: flex;
  margin-top: ${SPACING.S16};
`;
