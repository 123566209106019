import ApiService from '../util/apiService';
import * as ActionType from './types/RegistryActionTypes';

const requestCollectionInfoByRegistryId = () => ({
  type: ActionType.REQUEST_COLLECTION_INFO_BY_REGISTRY_ID,
});

const receivedCollectionInfoByRegistryId = (response) => ({
  type: ActionType.RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID,
  payload: response,
});

const requestRegistryBySlug = () => ({
  type: ActionType.REQUEST_REGISTRY_INFO_BY_SLUG,
});

const receivedRegistryBySlug = (response) => ({
  type: ActionType.RECEIVED_REGISTRY_INFO_BY_SLUG,
  payload: response,
});

const requestCollectionItemByCollectionId = () => ({
  type: ActionType.REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID,
});

const receivedCollectionItemByCollectionId = (response) => ({
  type: ActionType.RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID,
  payload: response,
});

const requestRegistryInfoByCollectionId = () => ({
  type: ActionType.REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID,
});

const receivedRegistryInfoByCollectionId = (response) => ({
  type: ActionType.RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID,
  payload: response,
});

const requestRegistry = () => ({
  type: ActionType.REQUEST_REGISTRY_INFO_BY_ID,
});

const receiveRegistry = (response) => ({
  type: ActionType.RECEIVED_REGISTRY_INFO_BY_ID,
  payload: response,
});

export const updateRegistryDefaultGiftCard = (enable) => (dispatch) => {
  return ApiService.put(`/web-registry-api/v1/registry/default-gift-card`, {
    gift_card_enabled: enable,
  }).then((json) => dispatch(receiveRegistry(json)));
};

const requestRegistryInfoBySlug = (slug) => (dispatch) => {
  dispatch(requestRegistryBySlug());
  return ApiService.get(`/web-registry-api/v1/registry/slug/${slug}`).then((json) =>
    dispatch(receivedRegistryBySlug(json))
  );
};

const fetchRegistryCollectionItem = (collectionItemId) => (dispatch) => {
  dispatch(requestCollectionItemByCollectionId());
  return ApiService.get(`/web-registry-api/v1/registryCollection/item/${collectionItemId}`).then(
    (json) => dispatch(receivedCollectionItemByCollectionId(json))
  );
};

const fetchRegistryInfoByCollectionItemId = (collectionItemId) => (dispatch) => {
  dispatch(requestRegistryInfoByCollectionId());
  return ApiService.get(`/api/v0/registry/collection-item-id/${collectionItemId}`).then((json) =>
    dispatch(receivedRegistryInfoByCollectionId(json))
  );
};

const fetchCollectionInfoByRegistryId = (registryId) => (dispatch) => {
  dispatch(requestCollectionInfoByRegistryId());
  return ApiService.get(`/web-registry-api/v1/registry/${registryId}/collection-previews`).then(
    (json) => dispatch(receivedCollectionInfoByRegistryId(json))
  );
};

const requestRegistryInfoById = () => (dispatch) => {
  dispatch(requestRegistry());
  return ApiService.get('/web-registry-api/v1/registry/user-info')
    .then((json) => {
      dispatch(receiveRegistry(json));
      return json;
    })
    .catch((e) => e);
};

export {
  receivedRegistryBySlug,
  requestRegistryInfoBySlug,
  fetchRegistryCollectionItem,
  fetchRegistryInfoByCollectionItemId,
  fetchCollectionInfoByRegistryId,
  requestRegistryInfoById,
};
