import type { WRegistryCollectionItemView, WReferencePrice } from '@zola/svc-web-api-ts-client';

import { convertCurrencyFormat } from '../../../util/currencyFormatter';

import imageNotFound from '../../../assets/images/no-image-available-400x400.jpg';

export const getBrandName = (item: WRegistryCollectionItemView) => {
  // @ts-expect-error Need to fix the enum type
  if (item.type === 'EXTERNAL' && !item.third_party_cash) {
    return '';
  }

  // @ts-expect-error Need to fix the enum type
  return item.brand?.name || (item.type === 'CASH' && 'Cash fund') || 'Zola';
};

export const getTileImage = (item: WRegistryCollectionItemView) =>
  item.images?.[0].medium || imageNotFound;

export const isFiltersEmpty = (filters?: Record<string, string[]>): boolean =>
  filters !== undefined && !Object.keys(filters).some((key) => Boolean(filters[key].length));

export const isItemAvailable = (item: WRegistryCollectionItemView) => {
  /**
   * show_as_fulfilled -> purchased
   * reserved -> 3 party gift on hold
   */
  const isPurchased = item.contributions?.show_as_fulfilled;
  const isReserved = item.contributions?.reserved;
  const isOutOfStock = !item.actions?.add_to_cart;
  const isDiscontinued = item.discontinued;

  const isAvailable = !isPurchased && !isReserved && !isOutOfStock && !isDiscontinued;

  return isAvailable;
};

const countDecimals = (value: number) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const getItemPriceLabel = (
  price: number,
  referencePrice?: WReferencePrice,
  showRefText = true
): string => {
  const minPriceDecimals = countDecimals(price) === 1 ? 2 : 0;
  const formattedPrice = convertCurrencyFormat(price, 'USD', minPriceDecimals, 2) as string;

  if (!referencePrice || !showRefText) return formattedPrice;

  switch (referencePrice.type) {
    case 'COMPAREATPRICE':
      return `Sale ${formattedPrice}`;
    case 'DISCOUNTPRICE':
      return `${formattedPrice}`;
    case 'MSRP':
      return `Our Price ${formattedPrice}`;
    default:
      return formattedPrice;
  }
};
