import React from 'react';
import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import { PricingWithStrikeThrough } from '@/components/common/PricingStrikeThrough';
import { RegistryTileProgressBar } from './RegistryTileProgressBar';
import { PriceContainer, BottomSection } from './RegistryTilePrice.styles';

interface RegistryTilePriceProps {
  item: WRegistryCollectionItemView;
}

export const RegistryTilePrice: React.FC<RegistryTilePriceProps> = ({ item }) => {
  if (!item.price) return null;

  const { reference_price: referencePrice = {}, promo_display_view: promoData, price } = item;

  const hasContributionHidden = item?.contributions?.hide_contributions;
  const isGiftProgressVisible =
    item.contributions?.group_gift &&
    !item.contributions?.show_as_fulfilled &&
    !hasContributionHidden;
  const remainPercentage = item.contributions?.percent_complete;

  return (
    <PriceContainer>
      <PricingWithStrikeThrough
        isPriceHidden={hasContributionHidden}
        referencePrice={referencePrice}
        promoData={promoData}
        price={price}
      />
      {isGiftProgressVisible && (
        <BottomSection>
          <RegistryTileProgressBar percentage={remainPercentage} />
        </BottomSection>
      )}
    </PriceContainer>
  );
};
