import React from 'react';
import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import { RegistryTilePrice } from './RegistryTilePrice';
import { BottomSection } from './RegistryTilePrice.styles';
import { RegistryTileContributions } from './RegistryTileContributions';
import { TileContainer, BrandName, ItemName } from './RegistryTile.styles';
import { PublicRegistryTileActions } from './PublicRegistryTileActions';
import { RegistryTileImage } from './RegistryTileImage';
import { RegistryTileShipment } from './RegistryTileShipment';
import { getBrandName, isItemAvailable } from './utils';

interface PublicRegistryTileProps extends React.HTMLAttributes<HTMLDivElement> {
  item?: WRegistryCollectionItemView;
  onItemClick: (item: WRegistryCollectionItemView, qty: number) => void;
  openItemDetail: (item: WRegistryCollectionItemView) => void;
}

export const PublicRegistryTile: React.FC<PublicRegistryTileProps> = ({
  item,
  onItemClick,
  openItemDetail,
  ...rest
}) => {
  if (!item) return null;

  const brandName = getBrandName(item);
  const isAvailable = isItemAvailable(item);

  return (
    <TileContainer isActive={false} onClick={() => openItemDetail(item)} {...rest}>
      <RegistryTileImage editable={false} item={item} />
      <BrandName>{brandName}</BrandName>
      <ItemName>{item.name}</ItemName>
      <RegistryTilePrice item={item} />
      <RegistryTileShipment item={item} />
      <BottomSection>
        {isAvailable && <PublicRegistryTileActions item={item} onItemClick={onItemClick} />}
        {isAvailable && <RegistryTileContributions contributions={item.contributions} />}
      </BottomSection>
    </TileContainer>
  );
};
