import { ApiService } from '@zola-helpers/client';
import _get from 'lodash/get';
import type { WCmsPageImageView, WUploadedImageView } from '@zola/svc-web-api-ts-client';
import { FileInfo } from '@uploadcare/react-widget';
import { AppDispatch } from './types';
import * as ActionTypes from './types/UploadcareActionTypes';

const FORMAT_TO_MIME_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
  BMP: 'image/bmp',
};
const getMimeTypeFromFormat = (uploadcareFile: FileInfo) =>
  FORMAT_TO_MIME_TYPE[_get(uploadcareFile, 'imageInfo.format') as keyof typeof FORMAT_TO_MIME_TYPE];

const MAX_COPY_ATTEMPTS = 2;

const transferStarted = (uploadcareFile: FileInfo) => ({
  type: ActionTypes.UPLOADCARE_TRANSFER_STARTED,
  payload: { uploadcareFile },
});

type TransferStartedAction = ReturnType<typeof transferStarted>;

const transferComplete = (metadata: WCmsPageImageView) => ({
  type: ActionTypes.UPLOADCARE_TRANSFER_COMPLETE,
  payload: { metadata },
});

type TransferCompleteAction = ReturnType<typeof transferComplete>;

export const copyUploadcareFileToZola = (
  uploadcareFile: FileInfo,
  preserveInUC: boolean,
  attemptNb = 0,
  daysToKeepInUploadCare?: number
): Promise<WUploadedImageView> => {
  return ApiService.post('/web-api/v1/image/uploadcare-save', {
    url: uploadcareFile.cdnUrl,
    contentType: uploadcareFile.mimeType || getMimeTypeFromFormat(uploadcareFile),
    preserveInUC,
    daysToKeepInUploadCare,
  }).then((json: any) => {
    const data = json?.data;
    if (!data && attemptNb < MAX_COPY_ATTEMPTS) {
      return copyUploadcareFileToZola(
        uploadcareFile,
        preserveInUC,
        attemptNb + 1,
        daysToKeepInUploadCare
      );
    }

    return data;
  });
};

export const fetchOriginalMetadata = (uploadcareImageId: string | null) => {
  return ApiService.get(`/web-api/v1/image/uploadcare-original/${uploadcareImageId}`)
    .then((json: any) => json?.data)
    .catch(() => ({})); // Return empty data if there is an error fetching the original
};

export const saveUploadcareImageToZola =
  ({
    uploadcareFile,
    cache = {},
    preserveInUC = false,
    daysToKeepInUploadCare = 0,
  }: {
    uploadcareFile: FileInfo;
    cache: Record<string, WUploadedImageView>;
    preserveInUC: boolean;
    daysToKeepInUploadCare?: number;
  }) =>
  (dispatch: AppDispatch) => {
    dispatch(transferStarted(uploadcareFile));
    const { cdnUrl, uuid } = uploadcareFile;
    const cacheValues = Object.values(cache);
    let filePromise;

    const cachedMetadata = cacheValues.find((info) => info.uploadcare_url === cdnUrl);
    const hasCachedOriginal = cacheValues.some((info) => info.uploadcare_original_url === cdnUrl);
    if (cachedMetadata) {
      // If the image is already available in the cache, directly use its metadata to avoid re-copying.
      filePromise = Promise.resolve(cachedMetadata);
    } else if (hasCachedOriginal) {
      // else if originalUrl && uploadcareFile.cdnUrl changes === originalUrl, fetch and use the original's zola file uuid
      filePromise = fetchOriginalMetadata(uuid);
    } else {
      // Otherwise, copy the new image to zola
      filePromise = copyUploadcareFileToZola(
        uploadcareFile,
        preserveInUC,
        0,
        daysToKeepInUploadCare
      );
    }

    return filePromise.then((metadata) => {
      dispatch(transferComplete(metadata));
      return metadata;
    });
  };

export type UploadcareActions = TransferStartedAction | TransferCompleteAction;
