import React, { useMemo, useCallback } from 'react';
import { FilterPills } from '@zola/zola-ui/src/components/FilterPill';
import { DesktopFilters } from './DesktopFilters';
import { MobileFilters } from './MobileFilters';
import { FiltersContainer, FilterPillsContainer } from './Filters.styles';
import { FiltersProps } from './type';
import { getFilterPillOptions } from './utils';

export const Filters: React.FC<FiltersProps> = (props) => {
  const { filters, onChange, onClear } = props;
  const pillOptions = useMemo(() => getFilterPillOptions(filters), [filters]);

  const onPillClick = useCallback(
    (value: string) => {
      const clickedOption = pillOptions.find((option) => option.value === value);
      onChange(clickedOption?.filterKey, clickedOption?.filterVal);
    },
    [pillOptions, onChange]
  );

  return (
    <FiltersContainer>
      <DesktopFilters {...props} />
      <MobileFilters {...props} />
      {pillOptions.length !== 0 && (
        <FilterPillsContainer>
          <FilterPills options={pillOptions} onPillClick={onPillClick} onClear={onClear} />
        </FilterPillsContainer>
      )}
    </FiltersContainer>
  );
};
