import React, { useCallback, useEffect, useState } from 'react';

import { RootState, useAppDispatch, useAppSelector } from '@/store';
import { SideDrawerV2 } from '@zola/zola-ui/src/components/SideDrawerV2';
import { updateRegistryDetails } from '../../../actions/ManageRegistryActions';

import CustomizeHeroContent from './CustomizeHeroContent';

import { ErrorMessages, HeroDetails } from './types';

interface CustomizeHeroSideDrawer {
  isOpen: boolean;
  onClose: () => void;
}

const CustomizeHeroSideDrawer: React.FC<CustomizeHeroSideDrawer> = ({
  isOpen = false,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const registry = useAppSelector((state: RootState) => state.registry);

  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    greeting: null,
    title: null,
  });
  const [heroDetails, setHeroDetails] = useState<HeroDetails | null>(null);

  useEffect(() => {
    if (!registry.status) return;

    setHeroDetails({
      greeting: registry.greeting,
      heroImage: registry.hero_image,
      heroImageUuid: registry.hero_image_uuid,
      profileImage: registry.profile_image,
      profileImageUuid: registry.profile_image_uuid,
      resetHeroImage: false,
      resetProfileImage: false,
      title: registry.name,
    });
  }, [registry]);

  const handleSaveDetails = useCallback(() => {
    const errors: ErrorMessages = {
      title:
        (!heroDetails?.title && 'Please add a header message') ||
        (heroDetails?.title &&
          heroDetails?.title.length > 40 &&
          'Please shorten your header message') ||
        null,
      greeting:
        heroDetails?.greeting && heroDetails?.greeting?.length > 1000
          ? 'Please shorten your welcome message'
          : null,
    };

    setErrorMessages({ ...errorMessages, ...errors });

    if (!errors.greeting && !errors.title) {
      const data = {
        ...heroDetails,
        registryObjectId: registry.registry_object_id,
      };

      return dispatch(updateRegistryDetails(data));
    }
    return false;
  }, [errorMessages, heroDetails, dispatch, registry.registry_object_id]);

  return (
    <SideDrawerV2
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSaveDetails}
      title="Customize"
      testId="customize-hero-drawer"
    >
      {heroDetails && (
        <CustomizeHeroContent
          errorMessages={errorMessages}
          heroDetails={heroDetails}
          setHeroDetails={setHeroDetails}
        />
      )}
    </SideDrawerV2>
  );
};

export default CustomizeHeroSideDrawer;
