import styled from '@emotion/styled';

export const FilterContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.COLORS3.BLACK_015};
  :last-of-type {
    border-bottom: none;
  }
`;

export const FilterHeader = styled.div`
  padding: ${(props) => props.theme.SPACING.S16} 0px;
`;

export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
`;

export const FilterLabel = styled.div`
  font-size: ${(props) => props.theme.FONTS.FONT_SIZE_SMALL};
  font-weight: ${(props) => props.theme.FONTS.FONT_WEIGHT_BOLD};
`;

export const FilterExpendIndentify = styled.div<{ expanded: boolean }>`
  height: 24px;
  width: 24px;
  transform: ${(props) => (props.expanded ? 'rotate(-180deg)' : 'initial')};
  transition: transform 0.3s ease;
`;

export const FilterContent = styled.div`
  padding: 0 0 ${(props) => props.theme.SPACING.S16} 0;
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.SPACING.SM};
`;

export const FilterSelectedOptions = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  color: ${(props) => props.theme.COLORS3.BLACK_050};
  font-size: ${(props) => props.theme.FONTS.FONT_SIZE_SMALL};
  line-height: 24px;
`;

export const FilterOption = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;
