import React, { useEffect, useMemo } from 'react';
import { FiltersProps } from './type';
import { SideDrawer } from './FilterSideDrawer.style';
import { FilterSideDrawerHeader } from './FilterSideDrawerHeader';
import { FilterSideDrawerContent } from './FilterSideDrawerContent';
import { FilterSideDrawerFooter } from './FilterSideDrawerFooter';

interface FilterSideDrawerProps extends FiltersProps {
  displayDrawer: boolean;
  total?: number;
  onClose: () => void;
  onClear?: () => void;
}

export const FilterSideDrawer: React.FC<FilterSideDrawerProps> = ({
  displayDrawer,
  total,
  sortBy,
  showSort,
  filters,
  onClose,
  onClear,
  onChange,
}) => {
  useEffect(() => {
    const HTMLTag = document.documentElement;
    HTMLTag.style.overflow = displayDrawer ? 'hidden' : '';

    return () => {
      HTMLTag.style.overflow = '';
    };
  }, [displayDrawer]);

  const hasActivedFilters = useMemo(() => {
    return filters.some((filter) =>
      Array.isArray(filter.value) ? filter.value.length : filter.value
    );
  }, [filters]);

  return (
    <SideDrawer displayDrawer={displayDrawer} onClose={onClose}>
      {{
        header: FilterSideDrawerHeader,
        content: (
          <FilterSideDrawerContent
            filters={filters}
            sortBy={sortBy}
            showSort={showSort}
            onChange={onChange}
          />
        ),
        footer: ({ handleClose }) => (
          <FilterSideDrawerFooter
            total={total}
            showClear={hasActivedFilters}
            onClose={handleClose}
            onClear={onClear}
          />
        ),
      }}
    </SideDrawer>
  );
};
