import type {
  WPromoDisplayView,
  WReferencePrice,
  WReferencePriceRange,
} from '@zola/svc-web-api-ts-client';

export enum PRICING_TYPES {
  DISCOUNTED = 'COMPAREATPRICE',
  MSRP = 'MSRP',
}

export enum DISCOUNT_TYPES {
  PERCENT_OFF = 'PERCENT_OFF',
  COMPLETION = 'COMPLETION',
}

export interface PromoContentProps {
  discountMessaging?: string;
  discountLink?: string;
}

export interface PricingStrikeThroughProps {
  isPriceHidden?: boolean;
  referencePrice?: WReferencePrice;
  promoData?: WPromoDisplayView;
  priceRange?: WReferencePriceRange;
  price: number;
}
