import React, { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react';

import type { FileInfo } from '@uploadcare/react-widget';
import openUploadcareModal from '@zola-helpers/client/dist/es/uploadcare/openUploadcareModal';

import { BulbIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Bulb';
import { CropIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Crop';
import { RefreshIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Refresh';
import { TrashIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Trash';
import { UploadIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Upload';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3';
import { useAppDispatch, useAppSelector } from '@/store';
import backgroundImageUploadcareTab from '../../BackgroundImageUploadcareTab';

import { getCachedMetadata } from '../../../../selectors/uploadcareSelectors';
import { saveUploadcareImageToZola } from '../../../../actions/UploadcareActions';

import { ErrorMessages, HeroDetails } from '../types';

import {
  BannerPreviewTile,
  CropImageCTA,
  CustomizeHeroWrapper,
  EditBannerCTAsWrapper,
  EditPhotoCTA,
  EditPhotoCTAsWrapper,
  ImageTileWrapper,
  PhotoPreviewTile,
  PhotoPreviewWrapper,
  StyledTextarea,
  UploadText,
  UploadTile,
} from './styles';

interface CustomizeHeroContentProps {
  errorMessages: ErrorMessages;
  heroDetails: HeroDetails;
  setHeroDetails: (heroDetails: HeroDetails) => void;
}

const customTabs = [backgroundImageUploadcareTab];

const CustomizeHeroContent: React.FC<CustomizeHeroContentProps> = ({
  errorMessages,
  heroDetails,
  setHeroDetails,
}) => {
  const dispatch = useAppDispatch();

  const cachedUCMetadata = useAppSelector(getCachedMetadata);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { greeting, heroImage, heroImageUuid, profileImage, profileImageUuid, title } = heroDetails;

  // Hero and profile image metadata aren't available on page render.
  // If available, use uploadcare url, otherwise fallback on url from registry details payload.
  const heroImageMetadata = heroImageUuid && cachedUCMetadata[heroImageUuid];
  const heroImageUrl =
    useMemo(
      () => (heroImageUuid && heroImageMetadata?.uploadcare_url) || null,
      [heroImageUuid, heroImageMetadata]
    ) || heroImage;

  const profileImageMetadata = profileImageUuid && cachedUCMetadata[profileImageUuid];
  const profileImageUrl =
    useMemo(
      () => (profileImageUuid && profileImageMetadata?.uploadcare_url) || null,
      [profileImageUuid, profileImageMetadata]
    ) || profileImage;

  const saveUploadcareFile = useCallback(
    (callback: (imageUUID: string) => void, uploadcareFile: FileInfo) => {
      setIsBusy(true);
      dispatch(
        saveUploadcareImageToZola({
          uploadcareFile,
          cache: cachedUCMetadata,
          preserveInUC: true,
        })
      )
        .then((metadata) => callback(metadata.uuid))
        .then(() => setIsBusy(false));
    },
    [setIsBusy, dispatch, cachedUCMetadata]
  );

  const startUCFlow = useCallback(
    (
      callback: (imageUUID: string) => void,
      cropRatio: string,
      minHeight: number,
      minWidth: number,
      showCustomTabs: boolean,
      editImageUrl?: string
    ) => {
      if (isBusy) return null;

      return openUploadcareModal({
        maxFileCount: 1,
        onUploadcareFileReady: (uploadcareFile) => {
          if (!uploadcareFile || typeof uploadcareFile === 'string') return null;

          return saveUploadcareFile(callback, uploadcareFile);
        },
        analyticsLocation: '',
        analyticsProduct: 'REGISTRY',
        cropRatio,
        customTabs: showCustomTabs ? customTabs : undefined,
        defaultTab: showCustomTabs && !editImageUrl ? backgroundImageUploadcareTab.key : undefined,
        minHeight,
        minWidth,
        editImageUrl,
      });
    },
    [isBusy, profileImageUrl, saveUploadcareFile]
  );

  const handleChangeHeroImage = () => {
    const callback = (imageUUID: string) =>
      setHeroDetails({ ...heroDetails, heroImageUuid: imageUUID, resetHeroImage: false });
    const editImageUrl = heroImageMetadata?.uploadcare_url;

    startUCFlow(callback, '1600x580 minimum', 580, 1600, true, editImageUrl);
  };

  const handleRemoveHeroImage = () =>
    setHeroDetails({
      ...heroDetails,
      heroImage: null,
      heroImageUuid: null,
      resetHeroImage: true,
    });

  const handleChangeProfileImage = () => {
    const callback = (imageUUID: string) =>
      setHeroDetails({ ...heroDetails, profileImageUuid: imageUUID, resetProfileImage: false });
    const editImageUrl = profileImageMetadata?.uploadcare_url;

    startUCFlow(callback, '300x300 minimum', 300, 300, false, editImageUrl);
  };

  const handleRemoveProfileImage = () =>
    setHeroDetails({
      ...heroDetails,
      profileImage: null,
      profileImageUuid: null,
      resetProfileImage: true,
    });

  const handleChangeTitle = (e: BaseSyntheticEvent) =>
    setHeroDetails({ ...heroDetails, title: e.target.value });
  const handleChangeGreeting = (e: BaseSyntheticEvent) =>
    setHeroDetails({ ...heroDetails, greeting: e.target.value });

  return (
    <CustomizeHeroWrapper>
      <InputFieldV3
        errorMessage={errorMessages.title}
        id="title"
        isRequired
        label="Header"
        maxChars={40}
        onChange={handleChangeTitle}
        value={title || ''}
      />
      <ImageTileWrapper>
        Banner
        {heroImageUrl ? (
          <>
            <BannerPreviewTile image={heroImageUrl} onClick={handleChangeHeroImage}>
              {heroImageMetadata && (
                <CropImageCTA onClick={handleChangeHeroImage}>
                  <CropIcon height={24} width={24} />
                  Crop
                </CropImageCTA>
              )}
            </BannerPreviewTile>
            <EditBannerCTAsWrapper>
              <UploadIcon onClick={handleChangeHeroImage} height={24} width={24} />
              <TrashIcon onClick={handleRemoveHeroImage} height={24} width={24} />
            </EditBannerCTAsWrapper>
          </>
        ) : (
          <UploadTile>
            <UploadText>
              <BulbIcon height={24} width={24} />
              This big photo grabs attention! Eye-catching horizontal shots often work best.
            </UploadText>
            <ButtonV3 onClick={handleChangeHeroImage} variant="secondary">
              Upload
            </ButtonV3>
          </UploadTile>
        )}
      </ImageTileWrapper>
      <ImageTileWrapper>
        Photo
        {profileImageUrl ? (
          <PhotoPreviewWrapper>
            <PhotoPreviewTile image={profileImageUrl} onClick={handleChangeProfileImage} />
            <EditPhotoCTAsWrapper>
              <EditPhotoCTA onClick={handleChangeProfileImage}>
                <RefreshIcon height={24} width={24} /> Swap
              </EditPhotoCTA>
              <EditPhotoCTA onClick={handleRemoveProfileImage}>
                <TrashIcon height={24} width={24} /> Remove
              </EditPhotoCTA>
            </EditPhotoCTAsWrapper>
          </PhotoPreviewWrapper>
        ) : (
          <UploadTile>
            <UploadText>
              <BulbIcon height={24} width={24} />
              Since this is small and circular, you might want to choose a close up shot.
            </UploadText>
            <ButtonV3 onClick={handleChangeProfileImage} variant="secondary">
              Upload
            </ButtonV3>
          </UploadTile>
        )}
      </ImageTileWrapper>
      <StyledTextarea
        errorMessage={errorMessages.greeting}
        id="greeting"
        label="Welcome message"
        maxChars={1000}
        onChange={handleChangeGreeting}
        placeholder="Welcome guests to your registry with a short message!"
        value={greeting || ''}
      />
    </CustomizeHeroWrapper>
  );
};

export default CustomizeHeroContent;
