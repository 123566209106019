import uploadcare from 'uploadcare-widget';
import type { DialogApi, Settings } from '@uploadcare/react-widget';
import {
  BACKGROUND_IMAGES,
  BackgroundImage,
  backgroundImageTabSvg,
} from './backgroundImageConstants';

import * as styles from './BackgroundImageUploadcareTab.styles';

function backgroundImageUploadcareTabConstructor(
  container: JQuery,
  button: JQuery,
  dialogApi: DialogApi,
  settings: Settings
): void {
  const $ = uploadcare.jQuery;

  const selectImage = (image: BackgroundImage) => {
    const ucfile = uploadcare.fileFrom('url', image.url, settings);
    dialogApi.addFiles([ucfile]);
  };

  // ----- RENDERING -----
  const tabElement = $(`
    <div class="${styles.tab}">
      <div class="${styles.title}">Choose from our exclusive gallery or upload your own</div>
    </div>
  `);
  const gridElement = $(`<div class="${styles.grid}"></div>`);

  BACKGROUND_IMAGES.forEach((image) => {
    const gridItemElement = $(`
      <div class="${styles.gridItem}">
        <img class="${styles.image}" id="${image.name}" src="${image.thumbnail}">
      </div>
    `);
    gridItemElement.on('click', () => selectImage(image));
    gridElement.append(gridItemElement);
  });

  tabElement.append(gridElement);
  container.append(tabElement);
}

export default {
  key: 'registry-background-gallery',
  constructor: backgroundImageUploadcareTabConstructor,
  sidebarTitle: 'Zola Exclusives',
  sidebarIcon: backgroundImageTabSvg,
};
