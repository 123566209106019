import React from 'react';
import type { WRegistryCollectionItemContributionView } from '@zola/svc-web-api-ts-client';

// Typography
import P from '@zola/zola-ui/src/typography/Paragraphs';
import T from '@zola/zola-ui/src/typography/Text';

// Components
import { ContributionContainer } from './RegistryTilePrice.styles';

interface ContributionsProps {
  contributions?: WRegistryCollectionItemContributionView;
}

export const RegistryTileContributions: React.FC<ContributionsProps> = ({ contributions }) => {
  if (!contributions) return null;

  const {
    group_gift: isGroupGift,
    still_needs: stillNeeds = 0,
    hide_contributions: hideContributions,
  } = contributions;

  const contributeText = hideContributions
    ? 'Contribute what you wish'
    : `${stillNeeds} still needed`;

  return isGroupGift ? (
    <ContributionContainer>{contributeText}</ContributionContainer>
  ) : (
    <ContributionContainer>
      <P.BodySmall>
        Asking for <T.Strong>{contributions.goal_units}</T.Strong>
      </P.BodySmall>
      <P.BodySmall>
        Purchased <T.Strong>{contributions.completed_units}</T.Strong>
      </P.BodySmall>
    </ContributionContainer>
  );
};
