import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { ButtonWrapper } from '@zola/zola-ui/src/components/ButtonV3/ButtonV3.styles';

export const TileEditActions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  row-gap: ${(props) => props.theme.SPACING.S8};
  right: ${(props) => props.theme.SPACING.S8};
  bottom: ${(props) => props.theme.SPACING.S8};

  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    display: none;

    right: ${(props) => props.theme.SPACING.S8};
    top: ${(props) => props.theme.SPACING.S8};
    bottom: unset;
  }
`;

export const QuoteContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

export const QuoteWrapper = styled.div`
  position: relative;
  transition: max-height ease 0.5s;
  max-height: 0;
  overflow: hidden;
`;

export const Quote = styled.div`
  background-color: ${(props) => props.theme.COLORS3.CLOUD_100};
  font-size: ${(props) => props.theme.FONTS.SIZE.EXTRA_SMALL};
  padding: ${(props) => props.theme.SPACING.S8};
  padding-top: ${(props) => props.theme.SPACING.MD};
`;

export const IconButton = styled(ButtonWrapper)<{ mobileOnly?: boolean }>`
  display: ${(props) => (props.mobileOnly ? 'flex' : 'none')};
  padding: 0;
  border: none;
  height: 44px;
  width: 44px;
  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    display: ${(props) => (props.mobileOnly ? 'none' : 'flex')};
  }
`;

export const MoveButton = styled(IconButton)`
  cursor: move;
`.withComponent('div');

export const QuoteButton = styled(ButtonV3)`
  position: absolute;
  width: 36px;
  height: 36px;
  padding: 0;
  left: 50%;
  transform: translate(-50%, -90%);
  z-index: 1;
  transition: transform ease 0.5s;
`;

export const TileImageContainer = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.theme.SPACING.S16};
  &:hover {
    ${TileEditActions} {
      display: flex;
    }
  }
  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    &:hover {
      ${QuoteButton} {
        transform: translate(-50%, -50%);
      }
      ${QuoteWrapper} {
        max-height: 200px;
      }
    }
  }
`;

export const TileImage = styled.img`
  display: block;
  border-radius: ${(props) => props.theme.SPACING.S16};
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  pointer-events: none;
`;
