import styled from '@emotion/styled';
import DropdownListBox from '@zola/zola-ui/src/components/Form/common/DropdownListBox';

export const FilterDropdownContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const StyledDropdownListBox = styled(DropdownListBox)`
  min-width: 280px;
  width: unset;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    > li,
    > div {
      padding: 10px 20px;
      &:hover {
        background-color: ${(props) => props.theme.COLORS3.BLACK_005};
      }
    }
  }
`;

export const ListOption = styled.li<{ selected?: boolean }>`
  color: ${(props) => props.theme.COLORS3.BLACK_100};
  ${(props) => props.selected && `background-color: ${props.theme.COLORS3.BLACK_005};`}
`;

export const FilterButton = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${(props) => props.theme.SPACING.SM} ${(props) => props.theme.SPACING.S16};
  border-radius: 100px;
  background: ${(props) => (props.active ? 'transparent' : props.theme.COLORS3.BLACK_005)};
  color: ${(props) => props.theme.COLORS3.BLACK_100};
  font-weight: ${(props) => props.theme.FONTS.FONT_WEIGHT_BOLD};
  border: 1px solid ${(props) => (props.active ? props.theme.COLORS3.BLACK_100 : 'transparent')};
  :hover {
    background: transparent;
    border: 1px solid ${(props) => props.theme.COLORS3.BLACK_100};
  }
  svg {
    margin-left: ${(props) => props.theme.SPACING.S8};
  }
`;
