import styled from '@emotion/styled';
import { StarFilledInIcon } from '@zola/zola-ui/src/components/SvgIconsV3/StarFilledIn';

export const TileTags = styled.div`
  position: absolute;
  top: ${(props) => props.theme.SPACING.SM};
  left: ${(props) => props.theme.SPACING.S8};
  svg {
    margin-right: ${(props) => props.theme.SPACING.S4};
  }
`;

export const StyledStartFilledInIcon = styled(StarFilledInIcon)`
  color: ${(props) => props.theme.COLOR_SEMANTIC.WARNING_PRIMARY};
`;
