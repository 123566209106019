import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import ApiService from '../util/apiService';
import Logger from '../util/logger';
import * as ActionType from './types/OverviewActionTypes';
import getMessagesDictionary from '../pages/Overview/NextStepsModule/messagesDictionary';

// keys message objects and creates an array of the user's relevant message objects for next steps module. Also, sends the relevant messages viewed to tracking.
const createUserMessages = (response) => {
  const messageKeys = response.next_steps;

  // filter keys that we get from backend for ones that have a match with the frontend messages dictionary
  const messagesDictionary = getMessagesDictionary(response.days_until_event);
  const filteredMessagesKey = messageKeys.filter((messageKey) => {
    if (messagesDictionary[messageKey]) {
      return true;
    }
    return false;
  });

  return filteredMessagesKey.map((filteredMessageKey) => messagesDictionary[filteredMessageKey]);
};

const hydrateOverviewMessages = (response) => ({
  type: ActionType.HYDRATE_OVERVIEW_MESSAGES,
  data: createUserMessages(response),
});

const fetchPlannerStats = (response) => ({
  type: ActionType.OVERVIEW_PLANNER_STATS,
  data: Object.assign(
    {},
    response.planner_stats,
    response.received_gift_stats,
    { num_unshipped_gifts: response.num_unshipped_gifts },
    { completion_discount_available: response.completion_discount_available },
    { event_passed: response.event_passed }
  ),
});

const toggleMessagesAndPlannerLoading = () => ({
  type: ActionType.MESSAGES_AND_PLANNER_IS_LOADING,
});

const requestProducts = () => ({
  type: ActionType.REQUEST_PRODUCTS,
});

const requestAdditionalProducts = () => ({
  type: ActionType.REQUEST_ADDITIONAL_PRODUCTS,
});

const receiveProducts = (response) => ({
  type: ActionType.RECEIVE_PRODUCTS,
  data: response,
});

const receiveAdditionalProducts = (response) => ({
  type: ActionType.RECEIVE_ADDITIONAL_PRODUCTS,
  data: response,
});

export function fetchMessagesAndPlanner() {
  return (dispatch) => {
    dispatch(toggleMessagesAndPlannerLoading());
    return ApiService.get('/web-registry-api/v1/overview')
      .then((json) => {
        dispatch(toggleMessagesAndPlannerLoading());
        dispatch(hydrateOverviewMessages(json));
        dispatch(fetchPlannerStats(json));
      })
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(
          toastsActions.negative({
            headline: 'Error getting registry overview messages and planner stats',
          })
        );
      });
  };
}

export function fetchProducts(data) {
  return (dispatch) => {
    dispatch(requestProducts());
    return ApiService.post('/web-registry-api/v1/overview/products', data)
      .then((json) => {
        dispatch(receiveProducts(json));
        return json;
      })
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: 'Error getting products' }));
      });
  };
}

export function fetchAdditionalProducts(data) {
  return (dispatch) => {
    dispatch(requestAdditionalProducts());
    return ApiService.post('/web-registry-api/v1/overview/products', data)
      .then((json) => {
        dispatch(receiveAdditionalProducts(json));
        return json;
      })
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: 'Error getting products' }));
      });
  };
}

export function addProduct(data) {
  return (dispatch) =>
    ApiService.post('/web-registry-api/v1/overview/product/add', data).catch((err) => {
      Logger.error(err.message, err);
      dispatch(toastsActions.negative({ headline: 'Error in adding product' }));
    });
}

export function rejectProduct(data) {
  return (dispatch) =>
    ApiService.post('/web-registry-api/v1/overview/product/reject', data).catch((err) => {
      Logger.error(err.message, err);
      dispatch(toastsActions.negative({ headline: 'Error in rejecting product' }));
    });
}

export function undoAddProduct(data) {
  return (dispatch) =>
    ApiService.post('/web-registry-api/v1/overview/product/undo', data).catch((err) => {
      Logger.error(err.message, err);
      dispatch(toastsActions.negative({ headline: 'Error in undoing product add' }));
    });
}
