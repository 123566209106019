import styled from '@emotion/styled';
import { BaseSideDrawer } from '@zola/zola-ui/src/components/SideDrawer';

export const SideDrawer = styled(BaseSideDrawer)`
  .side-drawer__body.side-drawer__opening {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .side-drawer__background-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
