import styled from '@emotion/styled';

export const ManageRegistryTilesContainer = styled.div`
  margin: 0 -${(props) => props.theme.SPACING.MD};
  border-top: 1px solid var(--zola-grid-border-color, ${(props) => props.theme.COLORS3.BLACK_030});
  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    margin: 0;
    border-top: none;
  }
`;
