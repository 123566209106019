import { css } from '@emotion/react';
import FONTS from '@zola/zola-ui/src/styles/emotion/fonts';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const tab = css`
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: ${SPACING.S32};
  width: 100%;
`;

export const title = css`
  /* color: @color-black; */
  ${FONTS.textSizeCompact(FONTS.FONT_SIZE_LARGE)}
  font-family: ${FONTS.FONT_FAMILY_SANS_SERIF};
  font-weight: ${FONTS.FONT_WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.S16};
`;

export const grid = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${SPACING.S16};

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const gridItem = css`
  cursor: pointer;
  transition: 200ms box-shadow;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    filter: brightness(90%);
  }
`;

export const image = css`
  aspect-ratio: 712 / 258;
  height: 100%;
  width: 100%;
`;
