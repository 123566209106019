import React from 'react';
import TagV2, { TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import { LockIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Lock';
import { GiftGroupIcon } from '@zola/zola-ui/src/components/SvgIconsV3/GiftGroup';
import { CheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Check';
import { PigIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Pig';

import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import { TileTags, StyledStartFilledInIcon } from './RegistryTileTags.styles';

const ICON_SIZE = { width: 14, height: 14 };

interface RegistryTileTagProps {
  item: WRegistryCollectionItemView;
}

export const RegistryTileTag: React.FC<RegistryTileTagProps> = ({ item }) => {
  // @ts-expect-error svc-web-api-client type
  const isThirdPartyGift = item.type === 'EXTERNAL';
  const isPurchased = item.contributions?.show_as_fulfilled;
  const isMostWanted = item.most_wanted;
  const isCashFund = item.cash_fund && !isThirdPartyGift;
  const isVenmo = item.third_party_cash_identifier;
  const isFeaturedFund = isMostWanted && (isCashFund || isVenmo);
  const isGroupGift = item.contributions?.group_gift;
  const isOnHold = item.contributions?.reserved;
  const isOutOfStock = !item.actions?.add_to_cart;
  const isDiscontinued = item.discontinued;

  if (isPurchased) {
    return (
      <TagV2 variant={TagV2Variant.BAY}>
        <CheckIcon {...ICON_SIZE} />
        Purchased
      </TagV2>
    );
  }

  if (isOnHold) {
    return (
      <TagV2>
        <LockIcon {...ICON_SIZE} />
        On hold
      </TagV2>
    );
  }

  if (isDiscontinued) {
    return <TagV2 variant={TagV2Variant.RED}>Discontinued</TagV2>;
  }

  if (isOutOfStock) {
    return <TagV2 variant={TagV2Variant.RED}>Out of stock</TagV2>;
  }

  if (isFeaturedFund) {
    return (
      <TagV2>
        <PigIcon {...ICON_SIZE} />
        Our featured fund
      </TagV2>
    );
  }

  if (isMostWanted) {
    return (
      <TagV2>
        <StyledStartFilledInIcon {...ICON_SIZE} />
        Our most wanted
      </TagV2>
    );
  }

  if (isGroupGift) {
    return (
      <TagV2>
        <GiftGroupIcon {...ICON_SIZE} />
        Group gift
      </TagV2>
    );
  }

  return null;
};

interface RegistryTileTagsProps {
  item: WRegistryCollectionItemView;
}

export const RegistryTileTags: React.FC<RegistryTileTagsProps> = ({ item }) => (
  <TileTags>
    <RegistryTileTag item={item} />
  </TileTags>
);
