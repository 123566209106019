import { createSelector } from 'reselect';
import { RootState } from '../store';

const getUploadcareState = (state: RootState) => state.uploadcare;

export const getCachedUploadcareFiles = createSelector(
  getUploadcareState,
  (uploadcareState) => uploadcareState.uploadcareFiles
);

export const getCachedMetadata = createSelector(
  getUploadcareState,
  (uploadcareState) => uploadcareState.zolaMetadata
);
