import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { ZINDEX } from '@zola/zola-ui/src/styles/emotion';

interface ImageComponentProps {
  editable?: boolean;
  hasImage?: boolean;
}
interface HeroProps {
  onClick?: () => void;
  editable?: boolean;
}

const COUPLE_IMG_OFFSET = '40px';

export const HeroBanner = styled.div<{ hasImage: boolean }>`
  ${({ theme, hasImage }) => `
    position: relative;
    padding-bottom: ${COUPLE_IMG_OFFSET};

    ${theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
      margin: 0 -${theme.SPACING.MD};
    }
    ${
      hasImage &&
      `
      &:before { 
        content: '';
        z-index: 1; 
        width: 100%;
        height: calc(100% - ${COUPLE_IMG_OFFSET});
        display: block;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 70%);
  
        ${theme.MEDIA_QUERY.DESKTOP} {
          border-radius: 16px;
        }
      }
    `
    }
    
    
  `}
`;

export const HeroBannerText = styled.div<{ darken: boolean }>`
  ${({ theme, darken }) => `
    position: absolute;
    top: 0;
    bottom: ${COUPLE_IMG_OFFSET};
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: ${theme.FONTS.FONT_WEIGHT_SEMI_BOLD};
    justify-content: center;
    z-index: ${ZINDEX.middle}; 

    color: ${darken ? theme.COLORS3.BLACK_100 : theme.COLORS3.WHITE_100};
  `}
`;

export const HeroBannerImage = styled.img<ImageComponentProps>`
  ${({ theme, editable, hasImage }) => `
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: ${theme.SCREEN_MASSIVE}px;
    max-height: 266px;
    height: 266px;

    background-color: ${theme.COLORS3.CLOUD_100};
    object-fit: cover;
    background-size: cover;
    
    ${editable && !hasImage ? `border: 1px dashed ${theme.COLORS3.BLACK_100};` : ''}

    ${theme.MEDIA_QUERY.DESKTOP} {
      border-radius: 16px;
    }
    ${
      !editable &&
      `
      ${theme.MEDIA_QUERY.DESKTOP_XL} {
        max-height: revert;
        height: 320px;
      }
  `
    }
    

  `}
`;

export const HeroCoupleImage = styled.img<ImageComponentProps>`
  ${({ theme, hasImage }) => `
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: ${theme.COLORS3.CLOUD_100};
    background-size: cover;
    background-position: center;
    border: 4px solid ${theme.COLORS3.WHITE_100};

    ${theme.MEDIA_QUERY.MOBILE} {
      height: 64px;
      width: 64px;
    }

    ${
      !hasImage &&
      `
      border: 1px dashed ${theme.COLORS3.BLACK_100};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.COLORS3.BLACK_100};
    `
    }
  `}
`;

export const HeroCoupleName = styled.div`
  ${({ theme }) => `
    text-align: center;
    font-size: ${theme.FONTS.FONT_SIZE_SEMI_HUGE};
    line-height: 1;
  `}
`;

export const HeroWeddingDate = styled.div`
  ${({ theme }) => `
    text-align: center;
    padding-top: ${theme.SPACING.S24};
    ${theme.FONTS.textSizeCompact(theme.FONTS.FONT_SIZE_SMALL)}
  `}
`;

export const HeroEditButtonText = styled.span`
  ${({ theme }) => `
    margin-left: ${theme.SPACING.SM};
  `}
`;

export const HeroEditButton = styled(ButtonV3)<{ editable?: boolean }>`
  display: ${(props) => (props.editable ? 'block' : 'none')};
  ${({ theme }) => `
    position: absolute;

    ${theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
      bottom: ${theme.SPACING.S16};
      right: ${theme.SPACING.S16};
      padding: 0;
      height: 44px;
      width: 44px;

      ${HeroEditButtonText} {
        display: none;
      }
    }

    ${theme.MEDIA_QUERY.DESKTOP} {
      display: none;
      top: ${theme.SPACING.S24};
      right: ${theme.SPACING.S24};
    }
  `}
`;

export const HeroGreetingSection = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.SPACING.S24} 0;
    width: 100%;

    ${theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
      padding: ${theme.SPACING.XS} 0 ${theme.SPACING.S24};
  `}
`;

export const HeroGreetingContent = styled.span`
  ${({ theme }) => `
    color: ${theme.COLORS3.BLACK_075};
    ${theme.FONTS.textSizeCompact(theme.FONTS.FONT_SIZE_SMALL)}
    text-align: center;
  `}
`;

export const Hero = styled.div<HeroProps>`
  ${({ theme, editable }) =>
    editable &&
    `
    cursor: pointer;

    ${theme.MEDIA_QUERY.DESKTOP} {
      &:hover {
        opacity: 0.9;
        ${HeroEditButton} {
          display: inline-flex;
        }
      }
    }
  `}
`;
