import styled from '@emotion/styled';

// Componenets
import TextareaFieldV2 from '@zola/zola-ui/src/components/Form/TextareaFieldV2/TextareaFieldV2';

export const CustomizeHeroWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    row-gap: ${theme.SPACING.S16};
  `}
`;

export const ImageTileWrapper = styled.div`
  position: relative;
`;

export const UploadTile = styled.div`
  ${({ theme }) => `
    align-items: center;
    background-color: ${theme.COLORS3.CLOUD_100};
    border: 1px dashed ${theme.COLORS3.BLACK_100};
    border-radius: 16px;
    display: flex;
    flex-shrink: 0;
    margin-top: ${theme.SPACING.XS};
    min-height: 116px;
    padding: ${theme.SPACING.S24} ${theme.SPACING.S16};
  `}
`;

export const UploadText = styled.div`
  ${({ theme }) => `
    ${theme.FONTS.textSizeCompact(theme.FONTS.FONT_SIZE_SMALL)};
    color: ${theme.COLORS3.BLACK_100};
    display: flex;
    margin-right: ${theme.SPACING.S16};

    svg {
      flex-shrink: 0;
      margin-right: ${theme.SPACING.XS};
    }
  `}
`;

export const BannerPreviewTile = styled.div<{ image: string }>`
  ${({ image, theme }) => `
    background: url(${image}) no-repeat center;
    background-size: cover;
    border-radius: 16px;
    cursor: pointer;
    margin-top: ${theme.SPACING.XS};
    max-height: 122px;
    min-height: 122px;
    padding-bottom: 23.5%;
    position: relative;
  `}
`;

export const CropImageCTA = styled.div`
  ${({ theme }) => `
    ${theme.FONTS.textSizeCompact(theme.FONTS.FONT_SIZE_SMALLER)};
    align-items: center;
    background: rgba(249, 249, 249, 0.7);
    border-radius: 8px;
    bottom: ${theme.SPACING.S16};
    column-gap: ${theme.SPACING.XXS};
    cursor: pointer;
    display: flex;
    font-weight: ${theme.FONTS.FONT_WEIGHT_SEMI_BOLD};
    height: 32px;
    justify-content: center;
    position: absolute;
    right: ${theme.SPACING.S16};
    width: 77px;
  `}
`;

export const EditBannerCTAsWrapper = styled.div`
  ${({ theme }) => `
    column-gap: ${theme.SPACING.XS};
    display: flex;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      cursor: pointer;
    }
  `};
`;

export const PhotoPreviewWrapper = styled.div`
  ${({ theme }) => `
    column-gap: ${theme.SPACING.S16};
    display: flex;
  `}
`;
export const PhotoPreviewTile = styled(BannerPreviewTile)`
  cursor: pointer;
  height: 116px;
  padding-bottom: 0;
  width: 116px;
`;

export const EditPhotoCTAsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: ${theme.SPACING.XS};
  `}
`;

export const EditPhotoCTA = styled.div`
  ${({ theme }) => `
    ${theme.FONTS.textSizeCompact(theme.FONTS.FONT_SIZE_SMALLER)};
    align-items: center;
    column-gap: ${theme.SPACING.XXS};
    cursor: pointer;
    display: flex;
  `}
`;

export const StyledTextarea = styled(TextareaFieldV2)`
  ${({ theme }) => `
    margin-bottom: ${theme.SPACING.XS};
  `}
`;
