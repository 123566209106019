export const SORTS = [
  { label: 'Featured', value: '' },
  {
    label: 'Lowest price',
    value: 'PRICE_ASC',
  },
  {
    label: 'Highest price',
    value: 'PRICE_DESC',
  },
];
