import styled from '@emotion/styled';
import { DiscountText } from '@/components/common/SimpleTile/SimpleTilePriceContent.styles';
import { RefPriceContainer, Price, ProgressBarContainer } from './RegistryTilePrice.styles';

export const TileActions = styled.div<{ showAction?: boolean }>`
  display: ${(props) => (props.showAction ? 'flex' : 'none')};
  column-gap: ${(props) => props.theme.SPACING.SM};
  > button {
    flex: 1 1 auto;
  }

  ${(props) => props.theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.theme.SPACING.S16};
    row-gap: ${(props) => props.theme.SPACING.XS};
  }
`;

export const ItemName = styled.div`
  font-weight: ${(props) => props.theme.FONTS.FONT_WEIGHT_BOLD};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
`;

export const TileContainer = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  min-width: 0px;
  min-height: 340px;
  padding: ${(props) => props.theme.SPACING.S8};
  padding-bottom: ${(props) => props.theme.SPACING.S24};
  border-bottom: 1px solid
    var(--zola-grid-border-color, ${(props) => props.theme.COLORS3.BLACK_030});
  border-right: 1px solid var(--zola-grid-border-color, ${(props) => props.theme.COLORS3.BLACK_030});
  display: flex;
  flex-direction: column;
  position: relative;

  font-size: ${(props) => props.theme.FONTS.FONT_SIZE_SMALLER};
  color: ${(props) => props.theme.COLORS3.BLACK_100};

  strong {
    font-weight: ${(props) => props.theme.FONTS.FONT_WEIGHT_BOLD};
  }

  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    padding: 0;
    padding-bottom: ${(props) => props.theme.SPACING.LG};
    border: none;
    font-size: ${(props) => props.theme.FONTS.FONT_SIZE_SMALL};
    min-height: 535px;
  }

  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    &:hover {
      ${({ isActive, theme }) =>
        isActive &&
        `
      ${TileActions} {
        display: flex;
        margin-top: ${theme.SPACING.S16};
      }
      ${RefPriceContainer}, ${Price}, ${ProgressBarContainer} {
        display: none;
      }
      ${DiscountText} {
        visibility: hidden;
      }
    `}
    }
  }
`;

export const BrandName = styled.div`
  height: 23px;
  color: ${(props) => props.theme.COLORS3.BLACK_050};
`;
