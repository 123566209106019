import styled from '@emotion/styled';

export const DesktopFiltersContainer = styled.div`
  display: none;

  ${(props) => props.theme.MEDIA_QUERY.DESKTOP} {
    display: block;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: ${(props) => props.theme.SPACING.S8};
`;

export const SortContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: ${(props) => props.theme.SPACING.S16};
`;
